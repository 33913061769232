const logoText = document.getElementsByClassName("logoText")[0] as HTMLParagraphElement;
const playerCount = document.getElementById("playerCount") as HTMLParagraphElement;
const momazosdiego = document.getElementById("momazosdiego") as HTMLVideoElement;
const momazos = document.getElementById("momazos") as HTMLDivElement;

let serverStatus:any = null;

const curPage = document.location.pathname.substring(1);
console.log(curPage);
if (curPage.startsWith("index") || curPage == "") {
    fetch('https://api.mcsrvstat.us/2/progresywnyminecraft.pl')
        .then(res => res.text())
        .then(text => {
            serverStatus = JSON.parse(text)
            playerCount.textContent = "Graczy Online: " + serverStatus.players.online;
            const players = serverStatus.players.list as Array<string>;
            players.forEach(player => {
                let span:HTMLSpanElement;
                playerCount.appendChild(span = document.createElement('span'));
                span.style.color = playerCount.style.color;
                span.textContent = player;
                span.innerHTML = "<br>" + span.innerHTML;
            });
            onResize();
        });
}

window.setInterval(update, 1)

function update() {
    if (curPage.startsWith("momazosdiego")) {
        if (momazosdiego.currentTime > 2.7 && !momazosdiego.ended) {
            window.scrollBy(0,10);
        }
        else {
            window.scrollBy(0, -1000);
        }
    }
}

onResize();
window.onresize = onResize;
function onResize() {
    // if (document.body.clientWidth < 530) {
    //     logoText.style.fontSize = "25px";
    // }
    // else {
    //     logoText.style.fontSize = "";
    // }

    if (!curPage.startsWith("momazosdiego")) {
        if (document.body.clientWidth < 800) {
            logoText.textContent = "Progresywny";
        }
        else {
            logoText.textContent = "Progresywny Minecraft";
        }
    }
    
    // const listParag = document.getElementsByTagName("p");
    // for (let i = 0; i < listParag.length; i++) {
    //     const p = listParag[i];

    //     p.style.fontSize = bound(document.body.clientWidth * 0.02, 18, 24) + "px";
    // }

    // const listH1 = document.getElementsByTagName("h1");
    // for (let i = 0; i < listH1.length; i++) {
    //     const h1 = listH1[i];

    //     h1.style.fontSize = bound(document.body.clientWidth * 0.02, 26, 33) + "px";
    // }
}

function bound(num:number, min:number, max:number):number {
  return num <= min 
    ? min 
    : num >= max 
      ? max 
      : num
}

window.onscroll = function(ev) {
    if (curPage.startsWith("momazosdiego")) {
        if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
            let img:HTMLImageElement;
                momazos.appendChild(img = document.createElement('img'));
                img.src = "assets/MOMAZOSDIEGO.png";
        }
    }
};